export default {
  error: {
    general: 'Etwas ist schief gelaufen...',
    loadingError: 'Daten konnten nicht geladen werden :/',
    channelExpired: 'Der Token ist abgelaufen.'
  },

  common: {
    confirm: 'Sind Sie sicher?',
    ok: 'Ok',
    yes: 'Ja',
    no: 'Nein',
    save: 'Speichern',
    cancel: 'Abbrechen',
    next: 'Weiter'
  },

  register: {
    createAccount: 'Konto erstellen',
    connectAccount: 'Verbinde dein eBay Konto mit Rootle',
    failed: 'Registrierung fehlgeschlagen.',
    passwordTooShort: 'Passwort muss min 8 Zeichen lang sein.',
    userAlreadyExists: 'Diese E-Mail-Adresse wird bereits von einem anderen Benutzer verwendet.'
  },

  confirm: {
    email: {
      error: 'Ein Fehler ist aufgetretten. Die E-Mail konnte nicht geändert werden.',
      success: 'E-Mail wurde erfolgreichn geändert.'
    },
  },

  plan: {
    limitReached: {
      title: 'Limit erreicht',
      p1: 'Leider ist die gewünschte Funktion mit Ihrem aktuellen Abonnement nicht möglich. Ihr aktueller Plan hat das Limit bereits erreicht. ',
      p2: "Bitte erwägen Sie ein Upgrade Ihres Abonnements, um zusätzliche Funktionen und Möglichkeiten zu nutzen. Wenn Sie Fragen haben oder Unterstützung benötigen, steht unser Support-Team Ihnen gerne zur Verfügung. E-Mail des Supports: <a href=\"mailto:{'hello'}{'@'}{'rootle.de'}\">{'admin'}{'@'}{'rootle.de'}</a>,"
    }
  },

  workspace: {
    overview: 'Workspaces Übersicht',
    tabs: {
      settings: 'Einstellungen',
      channels: 'Kanäle',
      templates: 'Nachrichtenvorlagen',
      members: 'Team',
      apps: 'Apps',
      automation: 'Automatisierung',
      orderDocuments: 'Auftragsdokumente',
    },

    channel: {
      overview: 'Übersicht meiner Kanäle',
      add: 'Kanal hinzufügen'
    },

    settings: {
      general: {
        headline: 'Allgemeine Einstellungen',

        name: {
          label: 'Workspace-Name',
          change: 'Workspace-Namen ändern'
        },
        description: {
          label: 'Beschreibung',
          change: 'Beschreibung ändern'
        }
      },

      messages: {
        headline: 'Nachrichten Einstellungen',
        indicator: 'Markieren Symbol',
        sendViaRootle: '"Versendet mit rootle.de" anhängen'
      },

      save: 'Einstellungen speichern',

      update: {
        ok: 'Einstellungen gespeichert.',
        error: 'Die Einstellungen konnten nicht gespeichert werden.'
      },
    },

    channels: {
      add: 'Kanal hinzufügen',
      overview: 'Übersicht meiner Kanäle',
      empty: 'Du hast noch keine Kanäle.',
      expired: 'Der Token für diesen Kanal ist abgelaufen.',
      manage: {
        title: 'Kanäle verwalten',
        deleteConfirm: 'Du bist dabei diesen Kanal zu löschen. Fortfahren?',
        deleteWarning: 'Alle Daten die mit diesem Kanal zusammenhängen werden endgültig gelöscht und können nicht wiederhergestellt werden!',
        deleted: 'Kanal wurde gelöscht.',
        synchronisation: 'Synchronisierung'
      }
    },

    members: {
      add: 'Benutzer einladen',
      invite: 'Einladung jetzt verschicken',
      email: 'E-Mail',
      channels: 'Kanäle',
      empty: 'Keine Kanäle vorhanden',

      manage: {
        title: 'Team verwalten',
        unanswered: 'Einladung unbeantwortet',
        invited: 'Einladung wurde verschickt.',
        deleted: 'Benutzer wurde aus dem Workspace entfernt.'
      }
    },

    templates: {
      headline: 'Vorlagen verwalten',

      manage: {
        templates: {
          add: 'Nachrichtenvorlage hinzufügen',
          edit: 'Nachrichtenvorlage bearbeiten',
          deleted: 'Vorlage wurde gelöscht.',
          saved: 'Vorlage wurde gespeichert.',
          created: 'Vorlage wurde hinzugefügt.',
          addError: 'Vorlage konnte nicht hinzugefügt werden.',
          updateError: 'Vorlage konnte nicht gespeichert werden.',
          deleteError: 'Vorlage konnte nicht gelöscht werden.',
          name: 'Name',
          content: 'Vorlage'
        },
        categories: {
          add: 'Kategorie hinzufügen',
          edit: 'Kategorie bearbeiten',
          deleted: 'Kategorie wurde gelöscht.',
          saved: 'Kategorie wurde gespeichert.',
          created: 'Kategorie wurde hinzugefügt.',
          addError: 'Kategorie konnte nicht hinzugefügt werden.',
          updateError: 'Kategorie konnte nicht gespeichert werden.',
          deleteError: 'Kategorie konnte nicht gelöscht werden.',
          name: 'Kategoriename',
          color: 'Farbe'
        }
      }
    },

    apps: {
      add: 'App hinzufügen',
      manage: {
        title : 'Apps verwalten'
      }
    }
  },

  account: {
    email: {
      headline: 'E-Mail-Adresse',
      button: 'E-Mail-Adresse ändern',
      new: 'Neue E-Mail-Adresse',
      current: 'Aktuelle E-Mail-Adresse',
      placeholder: 'Neue E-Mail-Adresse eingeben',
      confirmHeadline: 'E-Mail Änderung bestätigen',
      info: 'Geben Sie unten die neue E-Mail Adresse ein, die Sie Ihrem Konto zuordnen möchten. Wir senden einen Bestätigungscode an diese Adresse.',
      enterPassword: 'Bestätigen Sie die E-Mail Änderungen indem Sie ihr aktuelles Password eingeben:',
      confirmSend: 'Wir haben Ihnen eine Bestätigungsemail an die neue E-Mail Adresse gesendet.',
      error: 'E-mail konnte nicht geändert werden.'
    },

    name: {
      changed: 'Name geändert',
      error: 'Name konnte nicht geändert werden.'
    },

    password: {
      label: 'Passwort',
      placeholder: 'min. 8 Zeichen',
      current: 'Aktuelles Passwort',
      button: 'Passwort ändern',
      headline: 'Ändern Sie Ihr Passwort',
      success: 'Password wurde erfolgreich geändert. Sie werden nun ausgeloggt.',
      error: 'Passwort konnte nicht geändert werden.'
    },

    profile: {
      headline: 'Über Sie',
      name: 'Name',
      email: 'E-Mail'
    },

    save: 'Änderungen speichern',

    delete: {
      headline: 'Konto löschen',
      confirm: 'Wollen Sie Ihr Konto wirklich löschen?',
      info: 'Wenn Sie Ihr Konto löschen, haben Sie keinen Zugriff mehr auf die rootle-Konto-Dienste und Ihre personenbezogenen Daten werden dauerhaft gelöscht.',
      button: 'Konto löschen',
      success: 'Wir haben Ihnen eine Bestätigungs E-Mail zugeschickt.',
      error: 'Ein Fehler ist aufgetretten. Ihr Konto konnte nicht gelöscht werden.',
      deleted: 'Ihr Konto und alle Ihre personenbezogenen Daten werden dauerhaft gelöscht.'
    },

    workspace: {
      add: 'Workspace hinzufügen',
      name: 'Name',
      description: 'Beschreibung',
      noChannels: 'Sie haben noch keine Kanäle hinzugefügt.',
      manage: {
        headline: 'Workspaces verwalten',
        empty: 'Keine Workspaces vorhanden.',
        enterName: 'Bitte gib einen Workspace Namen ein.',
        created: 'Workspace wurde hinzugefügt.',
        nameInvalid: 'Dieser Workspacename ist nicht verfügbar. Versuchen Sie es mit einem anderen Namen.',
        limitReached: 'Dieser Workspacename ist nicht verfügbar. Versuchen Sie es mit einem anderen Namen.',
        deleted: 'Workspace wurde gelöscht.',
        disconnected: 'Workspace wurde entfernt',
      }
    },

    invitation: {
      completeAndContinue: 'Profil vervollständigen, um die Einladung zu akzeptieren',
      error: 'Ein Fehler ist aufgetretten.',
      accept: 'Registrieren & Einladung akzeptieren',
      notAllowed: 'Du bist nicht berechtigt die Einladung anzunehmen.',
      accepted: 'Einladung wurde akzeptiert.',
      goToLink: 'Direkt zum Workspace'
    }
  },

  sider: {
    dashboard: 'Übersicht',
    orders: 'Verkauft',
    purchased: 'Gekauft',
    messages: 'Nachrichten',
    forum: 'Forum',
    settings: 'Einstellungen',
  },

  order: {
    orderInformation: 'Bestellübersicht',
    orderId: 'Bestellnummer',
    orderDate: 'Bestellt am',
    buyer: 'Käufer',
    paymentMethod: 'Bezahlt mit',
    package: 'Paket',
    total: 'Gesamt',
    condition: 'Zustand',
    amount: 'Anzahl',
    priceUnit: 'Stückpreis',
    price: 'Preis',

    expected: 'Erwartet',
    shipping: {
      shippingDetails: 'Lieferadresse',
      shipTo: 'Versand an',
      deliveryCost: 'Versandkosten',
      free: 'kostenlos'
    },
    paymentStatus: {
      FAILED: 'Fehlgeschlagen',
      PARTIALLY_REFUNDED: 'Teilweise rückerstattet',
      FULLY_REFUNDED: 'Erstattet',
      PAID: 'Bezahlt',
      PENDING: 'Ausstehend',
      REFUNDED: 'Erstattet',
    }
  },

  cancellation: {
    title: 'Warum möchten Sie den Kauf abbrechen?',
    reason: {
      OUT_OF_STOCK_OR_CANNOT_FULFILL: 'Artikel nicht mehr vorrätig oder beschädigt',
      BUYER_ASKED_CANCEL: 'Käufer hat um Abbruch gebeten',
      ADDRESS_ISSUES: 'Problem mit der Lieferadresse des Käufers',
    }
  },

  Inquiry: {
    ProvideShipmentInfo: {
      name: 'Sendungsnummer hinzufügen',
      description: 'Wenn Sie Angaben zur Sendungsverfolgung hinzufügen, geben Sie dem Käufer die Sicherheit, dass der Artikel unterwegs ist.'
    },
    sendMessage: {
      name: 'Nachricht an Käufer senden',
      description: 'Sie können dem Käufer antworten.'
    },
    issueInquiryRefund: {
      name: 'Rückerstattung veranlassen',
      description: 'Sie können dem Käufer eine Rückerstattung senden, um diese Anfrage jetzt zu schließen.'
    }
  },

  components: {
    dashboard: {
      EBayStats: {
        feedback: 'Feedback',
        feedbackScore: 'Feedback Score',
        positiveFeedback: 'Positive Feedback',
        negativeFeedback: 'Negative Feedback'
      }
    },

    header: {
      LogoutButton: {
        signOff: 'Abmelden'
      },
      UserActions: {
        createAccount: 'Konto erstellen',
        addChannel: 'Kanal hinzufügen',
        settings: 'Mein Konto',
        addWorkspace: 'Workspace hinzufügen'
      }
    },

    message: {
      move: {
        done: 'Erledigt',
        moved: 'Konversation wurde nach \'{0}\' verschoben',
        undo: 'Die Aktion wurde rückgängig gemacht.'
      },
      list: {
        inbox: 'Offen',
        done: 'Erledigt'
      },
      thread: {
        Cancel: {
          cancelText: 'Abbrechen',
          cancelOrder: 'Kauf Abgebrochen',
          decision: 'Entscheidung'
        },
        Header: {
          done: 'Erledigt',
          inbox: 'Offen',
          trashBin: 'Papierkorb',
          move: 'Verschieben'
        },
        Inquiry: {
          refund: 'Veranlassen Sie eine Rückerstattung an den Käufer',
          initiateRefund: 'Rückerstattnug veranlassen',
          addMessage: 'Nachricht hinzufügen',
          refundValue: 'Rückerstattungsbetrag',
          itemOnTheWay: 'Artikel ist unterwegs',
          trackingNumber: 'Sendungsnummer',
          shippingFirm: 'Versandunternehmen',
          caseClosed: 'Die Anfrage wurde geschlossen',
          decision: 'Entscheidung'
        },

        note: {
          addNote: 'Notiz hinzufügen'
        },

        Reply: {
          sendMessage: 'Nachricht senden',
          cannotAnswerMessage: 'Auf diese Nachricht kann nicht geantwortet werden.',
          cannotWriteNewMessage: 'Wählen sie ein Artikel aus um eine Nachricht zu schreiben.',
          hello: 'Hallo',
          goodMorning: 'Guten Morgen',
          gutenTag: 'Guten Tag',
          goodEvening: 'Guten Abend',
          messageSent: 'Nachricht wurde verschickt.',
          messageNotSent: 'Die Nachricht konnte nicht verschickt werden',
          contactNotAllowed: 'Die Nachricht wurde nicht gesendet, da sie Kontaktinformationen enthält.',
          toMessage: 'Auf Nachricht antworten',
          newMessage: 'Neue Nachricht verfassen',
          toInquiry: 'auf Fall antworten',
          toReturn: 'auf Rückgabe antworten',
          locked: 'Diese Nachricht wird gerade von {0} bearbeitet.',
        },

        Return: {
          returnNr: 'Rücksendenr', // Rückerstattungsnr
          partialRefund: 'Wenn Sie eine Teilrückerstattung anbieten, behält der Käufer den Artikel',
          priceOffer: 'Preisvorschlag senden',
          initiateReturn: 'Wenn der Käufer das Rückerstattungsangebot akzeptiert, veranlassen Sie bitte eine Rückerstattung an ihn. Nutzen Sie dafür die gleiche Zahlungsmethode, mit der der Käufer gezahlt hat.',
          refundValue: 'Rückerstattungsbetrag vorschlagen',
          addMessage: 'Nachricht hinzufügen',
          makeOffer: 'Sie können einmalig ein Angebot machen. Wenn der Käufer das Angebot ablehnt, schaltet er möglicherweise eBay ein, damit wir ihm helfen können.',
          initiateRefund: 'Rückerstattung an Käufer veranlassen',
          refundNow: 'Jetzt rückerstatten',
          additionalInformation: 'Geben Sie dem Käufer noch ein paar Informationen. (Erforderlich mit Abzügen)',
          returnComplete: 'Die Rückgabe ist geschlossen',
          decision: 'Entscheidung: ',
          details: 'Einzelheiten',
          amount: 'Anzahl',
          price: 'Preis',
          condition: 'Zustand',
          variant: 'Variante',
          returnSent: 'Rückgabe verschickt',
          shipmentTracking: 'Sendungsverfolgung',
          priceSent: 'Ihr Preisvorschlag wurde gesendet\n',
          priceNotSent: 'Ihr Preisvorschlag konnte nicht gesendet werden',
          refundSuccess: 'Rückerstattung erfolgreich veranlasst',
          refundFail: 'Rückerstattung konnte nicht veranlasst werden.'
        },
        ReturnIssueRefund: {
          initiateRefundBuyer: 'Veranlassen Sie eine Rückerstattung an den Käufer',
          initiateRefund: 'Rückerstattung veranlassen',
          refundValue: 'Rückerstattungsbetrag'
        },
        SidebarContent: {
          orderInformation: 'Bestelldetails'
        },
        SingleMessage: {
          actionNeeded: 'Aktion erforderlich',
        }
      },
      Message: {
        noMessageSelected: 'Keine Nachricht ausgewählt',
      }
    },
    modal: {
      ProvideShipmentInfo: {
        addInformation: 'Sendungsnummer hinzufügen',
        addTrackingNr: 'Sendungsnummer hinzufügen',
        confirm: 'Ja, ich werde eine Sendungsnummer angeben.',
        trackingNr: 'Sendungsnummer hinzufügen',
        date: 'Versanddatum',
        shippingFirm: 'Versandunternehmen auswählen',
        other: 'Sonstiges',
        addsShippingFirm: 'Versandunternehmen hinzufügen',
        service: 'Service',
        notes: 'Anmerkungen',
        addDetails: 'Weitere Details hinzufügen (optional)',
      }
    },
    workspace: {
      ChangeMemberSettings: {
        manageUser: 'Nutzer verwalten',
        channels: 'Kanäle',
        noChannels: 'Keine Kanäle vorhanden',
        userUpdated: 'Benutzer wurde aktualisiert'
      }
    },
    BackToLogin: 'Zurück zum Login',
    ChangePasswordForm: {
      setNewPassword: 'Lege bitte ein neues Passwort für dein Konto fest',
      newPassword: 'Neues Passwort',
      confirmNewPassword: 'Neues Passwort bestätigen',
      setPassword: 'Passwort festlegen',
      mismatch: 'Die Passwörter stimmen nicht überein!'
    },
    Config: {
      shops: 'Shops',
      select: 'Shop auswählen',
      itemPage: 'Artikel per Seite',
      numberPages: 'Anzahl der Seiten',
      maxPrice: 'Max Preis',
      minNumber: 'Min Anzahl verkauft'
    },
    EBayLogin: {
      continue: 'Weiter mit'
    },
    ReportTable: {
      sold24: 'Nur verkauft in 24h',
      image: 'Bild',
      seller: 'Verkäufer',
      category: 'Kategorie',
      soldIn24: 'vk. in 24h',
      totalSold: 'Verkauft total',
      title: 'Titel',
      price: 'Preis',
      shipping: 'Versand'
    },

    dropbox: {
      add: 'Mit Dropbox verbinden'
    }

  }
}