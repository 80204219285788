import de from './src/i18n/de';

export default defineI18nConfig(() =>  ({
  legacy: false,
  locale: 'de',
  messages: {
    de
  }
}));

